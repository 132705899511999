import axios from 'axios'
//import router from '../router'
axios.defaults.timeout = 20000;
axios.defaults.baseURL = '/api'
import {Message} from 'element-ui';


//import { Message } from 'element-ui';


//http request拦截器 添加一个请求拦截器
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    let token = window.sessionStorage.getItem("tokenValue")
    if (token) {
        //将token放到请求头发送给服务器,将tokenkey放在请求头中
        let tokenName = window.sessionStorage.getItem('tokenName');
        //config.headers.tokenName = token;
        //也可以这种写法
        config.headers[tokenName] = token;
        return config;
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


//响应拦截器
axios.interceptors.response.use(success => {
    if (success.data.code && success.data.code == 500) {
        Message.error("请重新登录");
        sessionStorage.clear();
        this.$router.replace('/login');
    }

    if (success.data.code && success.data.code != 200 && success.data.code != 500) {
        Message.error(success.data.message);
    }

    return success;

}, error => {
    if (error.response.status == 504 || error.response.status == 404) {
        Message.error({message: '服务器被吃了o(╯□╰)o'});
    } else if (error.response.status == 403) {
        Message.error({message: '权限不足,请联系管理员!'})
    } else if (error.response.status == 500) {
        Message.error({message: '尚未登录或登录过期,请登录!'});
        this.$router.replace('/login');
    } else {
        if (error.response.data.message) {
            Message.error({message: error.response.data.message});
        } else {
            Message.error({message: '未知错误!'})
        }
    }
    return;
});
//
// //后期维护加请求路径
// let base ='';
//
// //传送json格式的post请求
// export const postRequest=(url,params)=>{
//   return axios({
//     method:'post',
//     url:'${base}${url}',
//     data:params
//   })
// }
