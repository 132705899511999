<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>

    export default {
        name: 'App',
        data(){
            return{
                beforeUnload_time:null,
                gap_time:null
            }
        },
        mounted() {
            // 关闭页面操作
            window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
            window.addEventListener('unload', e => this.unloadHandler(e))

        },
        destroyed() {
            window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
            window.removeEventListener('unload', e => this.unloadHandler(e))
        },
        methods: {
            beforeunloadHandler() {
                this.beforeUnload_time = new Date().getTime()
            },
            unloadHandler(e) {
                this.gap_time = new Date().getTime() - this.beforeUnload_time
                //判断是窗口关闭还是刷新
                if (this.gap_time <= 5) {
                    // 窗口关闭
                    localStorage.setItem('555',"555")
                }
            }
        }

    }
</script>

<style>
    #app {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /*text-align: center;*/
        color: #2c3e50;
        margin-top: 2px;
    }
</style>
