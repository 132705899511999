import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [
    {
        path: '/',
        redirect: '/login'
    },

    {
        path: '/login',
        name: 'Login',
        component: () => import("../views/Login")
    },
    {
        path: '/main',
        name: 'Main',
        component: () => import("../views/Main"),
        redirect: '/userList',
        children: [

            {
                //项目内容
                path: '/projectList',
                name: 'ProjectList',
                component: () => import("../views/project/ProjectList")
            },
            {
                //支付设置
                path: '/paySet',
                name: 'PaySet',
                component: () => import("../views/payset/PaySet")
            },
            {
                //账号管理
                path: '/accountList',
                name: 'AccountList',
                component: () => import("../views/account/AccountList")
            },
            //代理下级信息
            {
                path: '/accountDetail',
                name: 'AccountDetail',
                component: () => import("../views/account/AccountDetail")
            },
            //代理流量记录
            {
                path: '/liuLiangRecord',
                name: 'LiuLiangRecord',
                component: () => import("../views/account/LiuLiangRecord")
            },

            {
                //奖励设置
                path: '/rewardSet',
                name: 'RewardSet',
                component: () => import("../views/reward/RewardSet")
            },
            {
                //项目分类管理
                path: '/projectTypeList',
                name: 'ProjectTypeList',
                component: () => import("../views/projecttype/ProjectTypeList")
            },
            {
                //用户中心
                path: '/userList',
                name: 'UserList',
                component: () => import("../views/user/UserList")
            },
            {
                //下级用户
                path: '/userTeam',
                name: 'UserTeam',
                component: () => import("../views/user/UserTeam")
            },
            {
                //项目添加
                path: '/projectAdd',
                name: 'ProjectAdd',
                component: () => import("../views/project/ProjectAdd")
            },
            {
                //项目编辑
                path: '/projectEdit',
                name: 'ProjectEdit',
                component: () => import("../views/project/ProjectEdit")
            },
            {
                //投资详情
                path: '/projectDetal',
                name: 'ProjectDetal',
                component: () => import("../views/project/ProjectDetal")
            },
            {
                //充值审核
                path: '/upAudit',
                name: 'UpAudit',
                component: () => import("../views/upaudit/UpAudit")
            },
            {
                //提现审核
                path: '/withdrawal',
                name: 'Withdrawal',
                component: () => import("../views/withdrawal/Withdrawal")
            },
            {
                //数据统计
                path: '/data',
                name: 'Data',
                component: () => import("../views/data/Data")
            },
            {
                //沙盒
                path: '/shahe',
                name: 'Shahe',
                component: () => import("../views/shahe/Shahe")
            },
        ]
    }
]

const createRouter = () => new VueRouter({
    mode: 'history',
    routes: routes
})

const router = createRouter()

export default router
