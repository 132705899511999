import Vue from 'vue'
import App from './App.vue'

import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/utils/request';  //引入axios配置
import axios from 'axios';
import Vuex from 'vuex';

//import global from './common/js/Global';  //引入全局变量
/* 在 0.2.041 之前的版本需要引入 css */
// import 'jwchat/lib/JwChat.css';
//Vue.prototype.$global = global;

//获取值
//this.$global.api

import './assets/icon/account/iconfont.css'
import './assets/icon/projectType/iconfont.css'
import './assets/icon/project/iconfont.css'
import './assets/icon/paySet/iconfont.css'
import './assets/icon/reward/iconfont.css'
import './assets/icon/user/iconfont.css'
import './assets/icon/home/iconfont.css'
import './assets/icon/audit/iconfont.css'
import './assets/icon/data/iconfont.css'
import './assets/icon/chongzhi/iconfont.css'
import './assets/icon/system/iconfont.css'
import './assets/icon/vip/iconfont.css'
import './assets/icon/shahe/iconfont.css'

// import ZText from '@namchee/vue-ztext';
// Vue.use(ZText);

//websocket
import websocket from 'vue-native-websocket';

Vue.use(websocket, '', {
    connectManually: true, // 手动连接
    format: 'json', // json格式
    reconnection: true, // 是否自动重连
    reconnectionAttempts: 5, // 自动重连次数
    reconnectionDelay: 2000, // 重连间隔时间
});


Vue.prototype.axios = axios;

Vue.config.productionTip = false
Vue.use(ElementUI, {size: 'small'});
Vue.use(ElementUI);
Vue.use(Vuex);

//路由跳转之前
router.beforeEach((to, from, next) => {
    let Authorization = sessionStorage.getItem('tokenValue');
    if (to.path == '/logout') {
        //清空
        sessionStorage.clear();
        //跳转到登录页面
        next({path: '/login'});
    } else if (to.path == '/login') {
        //判断是否为空
        if (Authorization != null) {
            next({path: '/main'})
        }
    } else if (Authorization == null) {
        next({path: '/login'})
    }
    next();
});

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
